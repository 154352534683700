<template>
  <div>
    <b-row v-for="subTopic in data.subTopics" :key="subTopic.id">
        <b-card
            :class="
            $store.state.appConfig.layout.skin == 'dark'
                ? 'bg-custom-card-dark w-100'
                : ''
            "
        >
            <div
            :class="
                $store.state.appConfig.layout.skin == 'dark' ? 'text-white' : ''
            "
            >
            <div class="mb-5">
                <h3 class="d-inline">Question {{index+1}} /</h3>
                <span class="ml-25" v-if="data && data.subTopics.length > 0">{{ data.subTopics[0].MCQ.length }}</span>
            </div>
            <div>
                <h4>{{currentQuestion.question}}</h4>
            </div>

            <div class="pt-3">
                <b-row class="w-100">
                    <b-col md="4" class="p-2" v-for="option in currentQuestion.shuffle_options" :key="option.id">
                        <div :class="selectedClass(option)" @click="select(option)">
                            {{option.value}}
                        </div>
                    </b-col>
                </b-row>
            </div>
                <div class="w-100 d-flex justify-content-end p-2">
                        <!-- v-if=" index+1 != data.subTopics[0].MCQ.length" -->
                    <button
                        @click="submit"
                        :class="
                            selectedOption
                            ? 'btn btn-primary rounded-pill px-4'
                            : 'btn btn-secondary cursor-disabled rounded-pill px-4'
                        "
                        :disabled="loadSubmit"
                    >
                    <b-spinner v-if="loadSubmit" variant="light" style="width: 20px; height: 20px;" label="Spinning"></b-spinner>
                    <div v-else>Submit</div> 
                        
                    </button>
                </div>
            </div>
        </b-card>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import serverURL from "@/constants";
import VueApexCharts from "vue-apexcharts";

import {
  BIcon,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BAlert,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BSpinner,
    BCardBody,
    BTab,
    BTabs,
    BAlert,
    FeatherIcon,
    BIcon,
  },
  props: {
    data: { default: null },
  },
  data() {
    return {
      selectedOption: null,
      answers: [],
      currentQuestion: null,
      index: 0,
      type: 2
    };
  },
  computed: {
    ...mapGetters({
      item: "topics/item",
      loadSubmit:"lessonPlans/loadSubmit"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  methods: {
    checkQuestion(index){
        this.currentQuestion = this.data.subTopics[0].MCQ[this.index]
    },
    select(option) {
      this.selectedOption = option;
    },
    selectedClass(option) {
      if (this.selectedOption && option.id == this.selectedOption.id)
        return "border border-white rounded-lg w-100 p-2 cursor-pointer selected";
      return "border border-white rounded-lg w-100 p-2 cursor-pointer";
    },
    submit(){
        const question = this.currentQuestion;
        const answer = this.selectedOption;
        this.userAnswer = answer;
        
        try {
        const payload = { 
            question_id:this.currentQuestion.id ,
            selected_option: this.selectedOption.id,
            answer:this.userAnswer.id,
            type: this.type,
            topic_id: this.$route.params.id
        }
        this.$store.dispatch('lessonPlans/answerQue', payload).then((resp)=>{
                if( this.index+1 != this.data.subTopics[0].MCQ.length ){
                    this.selectedOption = null
                    this.index += 1
                    this.checkQuestion()
                }else{
                    this.$router.push({name:'finish_lesson'})
                }

                // this.$swal({
                //     icon: 'success',
                //     title: `<h3 style="color: #000">Submited</h3>`,
                //     showConfirmButton: false,
                //     timer: 2500,
                // })
            })
        } catch (error) {
            if (error.response) {
                this.$swal({
                    icon: 'error',
                    title: `<h4>${error.response.data.error}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: '#E84185',
                    allowOutsideClick: true
                });
            }
        }
    }
  },
  mounted(){
    this.checkQuestion()
  }
};
</script>

<style scoped>
.selected {
  border: 1px solid #e84185 !important;
}
</style>