<template>
  <div>
    <b-row v-for="subTopic in data.subTopics" :key="subTopic.id">
      <b-card
        :class="
              $store.state.appConfig.layout.skin == 'dark'
                  ? 'bg-custom-card-dark w-100'
                  : ''
              "
      >
        <div
          :class="
                  $store.state.appConfig.layout.skin == 'dark' ? 'text-white' : ''
              "
        >
        <b-form v-if="item.subTopics[0].attach"
            class="chat-app-form"
            @submit.prevent="submit" 
          >
            <validation-observer
              class="w-100"
              ref="fileForm"
              #default="{invalid}"
              
            >
              <b-row>
                <b-col
                  md="6"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="file"
                    vid="file"
                    rules="required"
                  >
                      <i
                        class="fa-solid fa-upload text-primary"
                        style="margin-bottom: -27px;margin-left: 10px; color: #E84185 !important"
                      ></i>
                      <b-form-file
                        id="file"
                        v-model="form.file"
                        :state="Boolean(form.file)"
                        class="custom-field ml-25"
                        accept=".pdf"
                        placeholder="Upload PDF File"
                      />
                    <small class="text-danger float-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  class="mb-2"
                > 
                  <div class="w-100 d-flex justify-content-center">
                    <div v-if="loadAttach" class="mt-2">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                    <b-button v-else
                      variant="primary"
                      class="rounded-pill mt-2"
                      type="submit"
                      :disabled="loadAttach"
                    >
                    
                      Submit
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>
          <embed
            v-if="item.subTopics[0].pdf_path"
            :src="item.subTopics[0].pdf_path"
            type="application/pdf"
            style="width: 100%; height: 600px;"
          />
        </div>
        <b-row v-if="showFinish || item.subTopics[0].attach == 0 " >
          <b-col md="12" class="d-flex justify-content-center">
            <button :disabled="loadFinish" class="btn btn-primary rounded-pill mt-3 px-4" @click="finish">
              <b-spinner v-if="loadFinish" variant="light" style="width: 20px; height: 20px;" label="Spinning"></b-spinner>
              <div v-else>Finish</div>
            </button>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>
  
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import serverURL from "@/constants";
import VueApexCharts from "vue-apexcharts";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import {
  BIcon,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BCardTitle,
  BCardBody,
  BTab,
  BFormGroup,
  BFormFile,
  BForm,
  BTabs,
  BAlert
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BSpinner,
    BCardBody,
    BTab,
    BTabs,
    BAlert,
    FeatherIcon,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BIcon
  },
  props: {
    data: { default: null }
  },
  data() {
    return {
      loadAttach:false,
      selectedOption: null,
      answers: [],
      currentQuestion: null,
      index: 0,
      showFinish: false,
      form: {
        file: null
      },
      type: 2
    };
  },
  computed: {
    ...mapGetters({
      item: "topics/item",
      loadFinish: "lessonPlans/loadFinish"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    }
  },
  methods: {
    submit() {
      // this.$refs.fileForm.validate().then(success => {
        if (this.form.file != null) {
          this.loadAttach = true;
          const formData = new FormData();
          formData.append("pdf", this.form.file);
          formData.append("topic_id", this.item.id);
          formData.append("sub_topic_id", this.item.subTopics[0].id);
          this.$store
        .dispatch('lessonPlans/AttachPDFAnswer',formData)
        .then(res => {
          this.loadAttach = false;
          this.showFinish = true;
          this.$swal({
            icon: "success",
            title: `<h4>Attached</h4>`,
            showConfirmButton: false,
            timer: 2500
          });
        })
        .catch(error => {
          this.loadAttach = false;
          if (error.response) {
            this.$swal({
              icon: "error",
              title: `<h4>${error.response.data.message}</h4>`,
              showConfirmButton: false,
              timer: 2500
            });
          }
        });
    
        }else{
          this.$swal({
              icon: "error",
              title: `<h4>Attach PDF</h4>`,
              showConfirmButton: false,
              timer: 2500
            });
        }
      // });
    },
    finish() {
      const payload = {
        topic_id: this.$route.params.id
      };
      this.$store.dispatch("lessonPlans/finishTopic", payload).then(resp => {
        this.$router.push({name: 'finish_lesson', params:{
          result: 100,
          module_id: resp.module_id,
          current_id: this.$route.params.id,
          next_id: resp.next_id
        }})
      });
    }
  },
  
};
</script>
  
<style>
.selected {
  border: 1px solid #e84185 !important;
}
/deep/.dark-layout .custom-file-label::after {
    display: none !important;
}
</style>