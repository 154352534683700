<template>
  <div>
    <b-row v-for="subTopic in data.subTopics" :key="subTopic.id">
      <b-card
        :class="
              $store.state.appConfig.layout.skin == 'dark'
                  ? 'bg-custom-card-dark w-100'
                  : ''
              "
      >
        <div
          :class=" 
                  $store.state.appConfig.layout.skin == 'dark' ? 'text-white' : ''
              "
          class="d-flex justify-content-center"
        > 
        <div  v-html="item.subTopics[0].Youtube"></div>
        
        <!-- <div v-if="embedUrl(item.subTopics[0].Youtube)" class="video-container"> -->
                <!-- <iframe
                    :src="embedUrl(item.subTopics[0].Youtube)"
                    width="560"
                    height="315"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe> -->
        <!-- </div> -->
                <!-- <p v-else>No video available</p> -->
          <!-- <div v-html="item.subTopics[0].Youtube"></div> -->
        </div>
        <b-row>
          <b-col md="12" class="d-flex justify-content-center">
            <button :disabled="loadFinish" class="btn btn-primary rounded-pill mt-3 px-4" @click="finish">
              
              <b-spinner v-if="loadFinish" variant="light" style="width: 20px; height: 20px;" label="Spinning"></b-spinner>
              <div v-else>Finish</div> 
            </button>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import serverURL from "@/constants";
import VueApexCharts from "vue-apexcharts";

import {
  BIcon,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BAlert
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BSpinner,
    BCardBody,
    BTab,
    BTabs,
    BAlert,
    FeatherIcon,
    BIcon
  },
  props: {
    data: { default: null }
  },
  data() {
    return {
      selectedOption: null,
      answers: [],
      currentQuestion: null,
      index: 0,
      type: 2
    };
  },
  computed: {
    ...mapGetters({
      item: "topics/item",
      loadFinish: "lessonPlans/loadFinish"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    }
  },
  methods: {
    embedUrl(youtube_link) {
        // Check if the URL is a valid YouTube link and convert it to the embed format
        if (youtube_link.includes('youtu.be')) {
            // For shortened YouTube URLs (e.g., https://youtu.be/t78ZZeYhsdw)
            const videoId = youtube_link.split('youtu.be/')[1];
            return `https://www.youtube.com/embed/${videoId}`;
        } else if (youtube_link.includes('watch?v=')) {
            // For standard YouTube URLs (e.g., https://www.youtube.com/watch?v=t78ZZeYhsdw)
            const videoId = youtube_link.split('watch?v=')[1];
            return `https://www.youtube.com/embed/${videoId}`;
        }
        return null;
    },
    finish() {
      const payload = {
        topic_id: this.$route.params.id
      };
      this.$store.dispatch("lessonPlans/finishTopic", payload).then(resp => {
        this.$router.push({name: 'finish_lesson', params:{
          result: 100,
          module_id: resp.module_id,
          current_id: this.$route.params.id,
          next_id: resp.next_id
        }})
      });
    }
  },
  mounted() {
    this.checkQuestion();
  }
};
</script>
  
  <style scoped>
.selected {
  border: 1px solid #e84185 !important;
}
</style>