<template>
    <div>
      <b-row v-for="subTopic in data.subTopics" :key="subTopic.id">
          <b-card
              :class="
              $store.state.appConfig.layout.skin == 'dark'
                  ? 'bg-custom-card-dark w-100'
                  : ''
              "
          >
              <div
              :class="
                  $store.state.appConfig.layout.skin == 'dark' ? 'text-white' : ''
              "
              >
              
                  <div class="w-100 ">
                    <img :src="item.subTopics[0].image" style="object-fit: cover;" alt="Image"  height="auto"  width="100%">
                  </div>
              </div>
              <b-row>
                <b-col md="12" class="d-flex justify-content-center">
                    <!-- <button class="btn btn-primary rounded-pill mt-3 px-4" @click="finish">Finish</button> -->
                    <button :disabled="loadFinish" class="btn btn-primary rounded-pill mt-3 px-4" @click="finish">
              
                    <b-spinner v-if="loadFinish" variant="light" style="width: 20px; height: 20px;" label="Spinning"></b-spinner>
                    <div v-else>Finish</div> 
                  </button>
                </b-col>
              </b-row>
          </b-card>
      </b-row>
    </div>
  </template>
<script>
  import axios from "axios";
  import { mapGetters } from "vuex";
  import serverURL from "@/constants";
  import VueApexCharts from "vue-apexcharts";
  
  import {
    BIcon,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BCardTitle,
    BCardBody,
    BTab,
    BTabs,
    BAlert,
  } from "bootstrap-vue";
  import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
  export default {
    components: {
      VueApexCharts,
      BCard,
      BRow,
      BCol,
      BCardText,
      BButton,
      BLink,
      BListGroup,
      BListGroupItem,
      BCardTitle,
      BSpinner,
      BCardBody,
      BTab,
      BTabs,
      BAlert,
      FeatherIcon,
      BIcon,
    },
    props: {
      data: { default: null },
    },
    data() {
      return {
        selectedOption: null,
        answers: [],
        currentQuestion: null,
        index: 0,
        type: 2
      };
    },
    computed: {
      ...mapGetters({
        item: "topics/item",
        loadFinish: "lessonPlans/loadFinish"
      }),
      id() {
        return this.$route.params.id ? this.$route.params.id : null;
      },
    },
    methods: {
      finish(){
        const payload = { 
            topic_id: this.$route.params.id
          }
        this.$store.dispatch('lessonPlans/finishTopic', payload).then((resp)=>{
          this.$router.push({name: 'finish_lesson', params:{
            result: 100,
            module_id: resp.module_id,
            current_id: this.$route.params.id,
            next_id: resp.next_id
          }})
        })
      },
      
      
    },
    mounted(){
      this.checkQuestion()
    }
  };
</script>
  
<style scoped>
.selected {
  border: 1px solid #e84185 !important;
}
</style>